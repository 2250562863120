import React, { useState, useEffect } from 'react';
import { Avatar, Box, Button, Card, Grid, Paper, Tab, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import OrganizationHeader from '../organizationheader';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import URL from '../../../../apiurl'
import { fetchProfile, handleActiveValue } from '../../../../../redux/Actions/actions';
import UserService from '../../../../../services/UserService';
import PublicFeed from './publicFeed';
import DialogBox from '../../../../DialogBox/dialog';
import orgcoverpic from '../../../../../icons/orgcoverpic.svg';
import { publicorgdata } from '../../../../../redux/Actions/actions';
import { headers } from '../../../../../redux/Actions/connectActions';
import { D_API } from '../../../../../services/APIService';
import { getLocation, getUserOrg, fetchPublicRouteSubOrganization } from '../../../../../redux/Actions/regActions';
import OrgLogo from '../../../../../images/orglogo.svg'
// import './organisation.css'
import '../AdminOrganization/organisation.css'
import PublicOverview from './publicOverview';
import PublicService from './publicService';
import PeopleOrg from '../PeopleOrg';
import AdminPublicationNew from '../amenities/adminPublicationNew';
import '../../../profile/profile.css';
import "../../../../utility/style.css";
import '../organization.css';
import { URI } from '../../../../utility/uri';
import ParticularPublicDeptsInfo from './particularPublicDeptsInfo/particularPublicDeptsInfo';
import ParticularPublicClinicalInfo from './particularPublicClinical/particularPublicClinicalInfo';


const SideTags = ({ Icon, title, className, data }) => {
  return (
    // <div className='flx mv1'>
    <div style={{ display: 'flex', gap: "7px", margin: "10px 0" }}>
      <span style={{ flex: 0.11 }}>{Icon} </span>
      <span style={{ flex: 1 }} className={className}>
        {title ? <>
          {title}
          <br />
          <span style={{ fontSize: '14px' }}>{data}</span>
        </>
          : data}
      </span>
    </div>
  )
}

const links = (text) => {
  return (
    text ? <a href={text} target="_blank" className='text_blue'>{text}</a> : 'N/A'
  )
}

const Imgs = (props) => {
  return <img width='24px' {...props} />
}

export default function OrganizationNew() {

  let active = JSON.parse(sessionStorage.getItem("org-active")) || "0"
  let { id } = useParams();

  const [tab, setTab] = useState('1')
  const [cover, setCover] = useState('')
  const matches = useMediaQuery('(max-width:960px)');
  const maxScreen = useMediaQuery('(min-width:961px)');
  const [people, setPeople] = useState([])
  const [profile, setProfile] = useState(parseInt(active.tab));
  const [user, setUser] = useState({})
  const [header, setHeader] = useState({})
  const [follow, setFollow] = useState('Follow')
  const [open, setOpen] = useState(false)

  const [docstethPageData, setDocstethPageData] = useState([])
  const [fromGetApiImages, setFromGetApiImages] = useState([])
  const [selectedParticularDept,setSelectedParticularDept]=useState(null);
  const[selectedParticularClinical,setSelectedParticularClinical]=useState(null);
  // console.log(fromGetApiImages, "fromGetApiImages")
  const active_value = useSelector(state => state.userProfile.active)
  const history = useHistory();

  let curPath = history.location.pathname;

  const dispatch = useDispatch();
  const [orgData, setOrgData] = useState({})

  const userOrg = useSelector(state => state.registration.userOrg)
  const state = useSelector(state => state.userProfile.publicorgdata)
  const Data = useSelector(state => state.registration.userOrgData);

  console.log(userOrg, "user", Data, state)

  const handleTabs = (e, value) => {
    setTab(value)
  }

  // // const getSelectedResource=()=>{
  //   selectedResource
  // }


  useEffect(() => {


    D_API.get(`${userOrg?.id}/${URI.getOrganizationByPageId}pageId/${id}`).then((res) => {
      let orgInfo = res.data?.entry?.[0]?.resource;
      if (orgInfo?.id) {
        dispatch(getUserOrg(orgInfo))
        setOrgData(res?.data?.entry?.[0]?.resource)
      }
      // console.log(res.data?.entry?.[0], "550");
    }).catch((err) => console.log(err));


  }, [id])

  useEffect(() => {
    if (id && curPath == `/organization/${id}`) {
      D_API.get(`${userOrg?.id}/Organization/identifier/prov/pageId/${Number(id)}`).then(res => {
        let orgId = res?.data?.entry?.[0]?.resource?.id;
        if (orgId) {
          getPublicsubOrgDetails(orgId);
        }
      }).catch((err) => console.log(err))
    }
  }, [id])


  const getPublicsubOrgDetails = (Id) => {
    D_API.get(`${userOrg?.id}/${URI.getFacilitiesOfSubOrg}${Id}&type=prov`).then((res) => {
      if (res.data?.entry?.length > 0) {
        let sortList = res.data?.entry?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value);
        dispatch(fetchPublicRouteSubOrganization(sortList));
      } else {
        dispatch(fetchPublicRouteSubOrganization([]));
      }
    }).catch((err) => console.log(err));
  }


  // unlock below function while using v1
  // useEffect(() => {
  //   if (id) {
  //     D_API.get(`Organization/identifier/pageId/${id}`).then(res => {
  //       // console.log(res, "rrrrrrr")
  //       // dispatch(getLocation(id))z
  //       dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
  //       setOrgData(res?.data?.entry?.[0]?.resource)
  //     }).catch((err) => console.log(err, '1222'))
  //   }
  // }, [id])

  useEffect(() => {
    if (userOrg?.id) {
      dispatch(getLocation(userOrg?.id))
    }
  }, [userOrg?.id])


  useEffect(() => {
    dispatch(handleActiveValue(0))
  }, [active_value])

  useEffect(() => {
    if (Number(active?.id !== id)) {
      handleActive(0)
    }
  }, [id])

  useEffect(() => {
    dispatch(publicorgdata(id))
  }, [])



  // console.log(state, '146')

  useEffect(() => {
    if (UserService.getToken()) {
      axios.get(`${URL.getPageById}/${id}`, headers())
        .then(res => {
          // console.log(res.data.data[0].page)
          setDocstethPageData(res.data?.data)
          setFromGetApiImages(res.data.data?.[0]?.page?.coverPhoto?.split(","))

          if (res.data?.data?.[0]?.page?.status === false) {
            setUser(res.data?.data?.[0]?.page)
            if (res.data?.data?.[0]?.followers?.includes(UserService.getUserId())) {
              setFollow("Following")
            }
            else {
              setFollow("Follow")
            }
          }
          else {
            history.push(`/admin/organization/${id}`)
          }
        }
        )
    }
    else {
      axios.get(`${URL.findPeopleByPageIdWithoutToken}/${id}`).then(res => {
      
        setPeople(res.data.data)
      })

    }
  }, [id])

  useEffect(() => {
    if (!UserService.getUserId() && state) {
      let data = [{
        page: { ...state }

      }]
      setDocstethPageData(data)
      setFromGetApiImages(data?.[0]?.page?.coverPhoto?.split(","))
    }

  }, [state])

  useEffect(() => {
    if (UserService.getToken()) {
      D_API.get(`${userOrg?.id}/Organization/identifier/pageId/${id}`).then(res => {

        if (res?.data?.entry?.[0]?.resource?.identifier?.[0]?.value !== UserService.getUserId()) {
       
          dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
          setOrgData(res?.data?.entry?.[0]?.resource)
        }
        else {
          history.push(`/admin/organization/${id}`)
        }
      }
      ).catch((err) => console.log(err, 'err'))
    }
    else {
      axios.get(`${URL.findPeopleByPageIdWithoutToken}/${id}`).then(res => {
        // console.log(res.data, '1977')
        setPeople(res.data?.data)
      })


      D_API.get(`${orgData?.id}/Organization/without_token/identifier/pageId/${id}`).then((res) => {
        setOrgData(res.data?.entry?.[0]?.resource)
      }).catch((err) => {
        console.log(err, "PagesByIdWithoutKeycloak")
      });


      // below code uncomment while using /v1 flow
      // axios.get(`${URL.getbyPageId}${id}`, headers1()).then(res => {
      //   setOrgData(res.data?.entry?.[0]?.resource)
      // }).catch(err => {
      //   console.log(err, "PagesByIdWithoutKeycloak")
      // })


    }
  }, [])

  const headers1 = () => {
    return (
      {
        headers: {
          'X-FHIR-TENANT-ID': 'docsteth',
          'X-FHIR-DSID': 'fhirDocsteth',
          'Realm': 'docsteth'
        }
      }
    )
  }


  const handleActive = (val) => {
    sessionStorage.setItem("org-active", JSON.stringify({ tab: val, id: id }))
    let v = JSON.parse(sessionStorage.getItem("org-active"))
    setProfile(v.tab)
  }

  const followOrg = () => {
    let uid = UserService.getUserId()
    let data = {
      "userId": uid
    }
    axios.post(`${URL.followOrganisation}/${id}/user/${uid}`, data, headers()).then(res => {
      // console.log(res)
      if (res.data?.status === 201) {
        setFollow('Following')
        dispatch(fetchProfile())
      }
    })
  }

  const unfollow = () => {
    setOpen(true)
  }

  const getPagedata = (data) => {
    // console.log(data)
    setHeader(data)
  }

  const unFollowPage = () => {
    let uid = UserService.getUserId()
    axios.delete(`${URL.removePageFollower}/${id}/User/${uid}`, headers()).then(res => {
      // console.log(res)
      if (res.data?.status === 200) {
        setFollow('Follow')
        setOpen(false)
        dispatch(fetchProfile())
      }
    })
  }

  return (
    <div className="Main_Header_orz app__one">
      <DialogBox
        width="xs"
        open={open}
        handleDialog={() => setOpen(false)}
        Content={<p>Do you want to unfollow ?</p>}
        Actions={
          <div>
            <Button
              variant="contained"
              className="add-option"
              sx={{ mr: 2 }}
              onClick={() => unFollowPage()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="add-option"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        }
      />

      {!UserService.getToken() ? (
        <div className="org_head">
          <Helmet>
            <title>{`${state.pageName} | Docsteth `}</title>
            <meta name="description" content={state.description} />
            <meta
              name="canonical"
              content={`https://www.docsteth.com/organization/${id}`}
            />
            <meta name="keywords" content={`${state.pageName}, Docsteth `} />
          </Helmet>
          <OrganizationHeader header={state} />
        </div>
      ) : (
        <Helmet>
          <title>{`${user.pageName} | Docsteth `}</title>
          <meta name="description" content={user.description} />
          <meta
            name="canonical"
            content={`https://www.docsteth.com/organization/${id}`}
          />
          <meta name="keywords" content={`${user.pageName}, Docsteth `} />
        </Helmet>
      )}

      {/* <div style={{ height: 'calc(100vh - 130px)', overflow: 'scroll', padding: "10px" }}> */}
      <div style={{ padding: "10px" }}>
        <Paper
          sx={{
            borderRadius: 0,
            boxShadow: "0px 3px 6px #00000029!important",
          }}
        >
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              height: `${cover ? "" : "200px"}`,
            }}
          >
            <div style={{ position: "relative" }}>
              {fromGetApiImages?.length > 0 ? (
                <Carousel
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                >
                  {fromGetApiImages?.map((item, i) => {
                    return (
                      <img
                        key={i}
                        src={
                          fromGetApiImages[i]
                            ? fromGetApiImages[i]
                            : orgcoverpic
                        }
                        className="profile__cover"
                        alt=""
                        width="100%"
                      />
                    );
                  })}
                </Carousel>
              ) : (
                <img
                  src={orgcoverpic}
                  className="profile__cover"
                  alt=""
                  width="100%"
                />
              )}
            </div>
          </div>
          {console.log(
            docstethPageData,
            "309",
            docstethPageData?.[0]?.page?.orgPageImage, fromGetApiImages
          )}
          <div style={{ backgroundColor: "#c7ced9", position: "relative" }}>
            <div style={{ marginTop: "-12px", background: "green" }}>
              {/* <div > */}
              <div className="show-image">
                {docstethPageData?.[0]?.page?.orgPageImage ? (
                  <Avatar
                    className="profile-pic"
                    id="img_orz"
                    src={
                      docstethPageData?.[0]?.page?.orgPageImage
                        ? docstethPageData?.[0]?.page?.orgPageImage
                        : OrgLogo
                    }
                    sx={{
                      backgroundColor: "#A8E8FE",
                      ".MuiAvatar-img": { width: "100%", height: "100%" },
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                      border: "4px solid #fff",
                    }}
                  />
                ) : (
                  <Avatar
                    className="profile-pic"
                    id="img_orz"
                    src={
                      docstethPageData?.[0]?.page?.orgPageImage
                        ? docstethPageData?.[0]?.page?.orgPageImage
                        : OrgLogo
                    }
                    sx={{
                      backgroundColor: "#A8E8FE",
                      ".MuiAvatar-img": { width: "60px", height: "60px" },
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                      border: "4px solid #fff",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Box
            sx={{
              marginLeft: { md: "180px" },
              marginTop: { xs: "30px", sm: "50px", md: "0" },
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4 className="orgname">{orgData?.name}</h4>
              <Typography style={{ marginTop: "-8px", marginBottom: "10px" }}>{orgData?.alias?.[0]}</Typography>
              <p
                className="capitalize"
                style={{ textTransform: "capitalize", marginBottom: "1rem" }}
              >
                {[
                  orgData?.address?.[0]?.line?.[0],
                  orgData?.address?.[0]?.district,
                  orgData?.address?.[0]?.state,
                  orgData?.address?.[0]?.country,
                  orgData?.address?.[0]?.postalCode,
                ]
                  ?.filter(Boolean)
                  ?.toString()
                  ?.replace(/,/g, ", ") || "N/A"}
              </p>
              <div className="mt-10" id="follow_padding">
                {UserService.getToken() ? (
                  <div>
                    <Button
                      id="basic-button"
                      className="organisation_button"
                      variant="outlined"
                      onClick={() =>
                        follow === "Follow" ? followOrg() : unfollow()
                      }
                    >
                      {follow}
                    </Button>
                    {/* <span className='more_icon'><MoreVertIcon /></span> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Box>
        </Paper>
        <Paper
          sx={{ borderRadius: 0, boxShadow: "none" }}
          className="org_layout"
        >
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <TabContext value={tab}>
                <Card
                  sx={{ borderRadius: 0, boxShadow: "0px 3px 6px #00000029" }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      scrollButtons="auto"
                      classes={{
                        indicator: { backgroundColor: "#0F22B5!important" },
                      }}
                      onChange={handleTabs}
                      aria-label="lab API tabs example"
                    >
                      {["Overview", "Departments", "Timeline", "Services", "People"].map(
                        (tab, i) => (
                          <Tab
                            className="capitalize"
                            key={i}
                            classes={{
                              selected: { color: "#0F22B5!important" },
                            }}
                            label={tab}
                            value={`${i + 1}`}
                          />
                        )
                      )}
                    </TabList>
                  </Box>
                </Card>
                <TabPanel value="1" sx={{ padding: "5px 0", backgroundColor: "#F4F2EE" }}>
                  <PublicOverview setTabValue={setTab} toselectedDept={setSelectedParticularDept}   toselectedClinical={setSelectedParticularClinical} />
                </TabPanel>
                <TabPanel value='2'>
                  <ParticularPublicDeptsInfo selectedItemOfDept={selectedParticularDept}/>
                </TabPanel>
                <TabPanel value="3" sx={{ padding: "5px 0" }}>
                  <div className="desktop_view_org">
                    {/* <Timeline getPagedata={getPagedata} /> */}
                    <Grid container className="Timeline__Desktop">
                      <Grid item md={4} style={{ paddingRight: "10px" }}>
                        <AdminPublicationNew pageUserId={docstethPageData?.[0]?.page?.pageAdmin} />
                      </Grid>
                      <Grid item md={8}>
                        <PublicFeed />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="orz_small_Devices">
                    <AdminPublicationNew pageUserId={docstethPageData?.[0]?.page?.pageAdmin} />
                    <PublicFeed />
                  </div>
                </TabPanel>
                <TabPanel value="4" sx={{ padding: "5px 0" }}>
                  <ParticularPublicClinicalInfo selectedItemOfClinical={selectedParticularClinical}/>
                  {/* <Services /> */}
                  {/* <PublicService /> */}
                </TabPanel>
                <TabPanel value="5" sx={{ padding: "5px 0" }}>
                  <PeopleOrg  publicView={true}/>
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
