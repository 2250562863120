import { jwtDecode } from "jwt-decode";
import axios from 'axios';

import UserService from "./UserService";


/**
 * Request handler
 * @param {Object} reqConfig - Request config object
 */
export const RequestInterceptor = (reqConfig) => {
    try {
        reqConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        const kctoken = UserService.getToken();
        reqConfig.headers['Authorization'] = `Bearer ${kctoken}`
        return reqConfig;
    } catch (error) {
        return Promise.reject(error);
    }
};
/**
 * Response handler
 * @param {Object} error - Denotes API error
 */
// export const ResponseInterceptor = (resConfig) => {
//     try {
//         return resConfig;
//     } catch (error) {
//         return Promise.reject(error);
//     }
// };


const fetchNewAccessTokenInReponseBody = async () => {
    const refreshdata = {
        refreshToken: UserService.getRefreshToken(),
        clientId: UserService?.getClientName() || localStorage.getItem('client')
    };


    try {

        if (sessionStorage?.getItem("kctoken")) {
            const refreshToken = UserService.getRefreshToken();
            const decoded = jwtDecode(sessionStorage?.getItem("kctoken"));
            console.log(decoded, "decoded")
            let exp = decoded?.exp;
            let currentTime = Math?.floor(Date.now() / 1000);
            if (currentTime && exp) {
                if (typeof refreshToken === 'string' && refreshToken?.trim() !== '') {
                    const response = await axios.post(
                        process.env.REACT_APP_API_URL + `v1/AccessTokenByRefreshToken`,
                        refreshdata
                    );
                    const { access_token, refresh_token } = response.data;
                    sessionStorage?.setItem("kctoken", access_token);
                    sessionStorage?.setItem("refresh_token", refresh_token);
                } else {
                    console.error("Invalid refresh token:", refreshToken);
                }
            }
        }
    } catch (error) {
        console.error("Error refreshing access token:", error);
    }
};

export const ResponseInterceptor = (response) => {
    try {

        if (response.status === 401) {
            const refreshToken = UserService.getRefreshToken();
            if (refreshToken) {
                fetchNewAccessTokenInReponseBody();
            } else {
                UserService.doLogout();
            }
        }
        return response;

    } catch (error) {
        return Promise.reject(error);
    }
};

// For handling errors (rejected requests)


export const ErrorInterceptor = async (error) => {
    try {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                const refreshToken = UserService.getRefreshToken();
                if (refreshToken) {
                    fetchNewAccessTokenInReponseBody();
                }
            }
        } else {
            UserService.doLogout();
        }
    } catch (error) {
        console.error("Error refreshing access token:", error);
    }

    return Promise.reject(error); // Return the error to continue the promise rejection
};





/**
 * Error handler
 * @param {Object} error - Denotes API error
 */
export const ResponseErrorInterceptor = (reqConfig) => {
    if (reqConfig.response) {
        if (reqConfig.response.status === 401) {
            window.location.href = "/";
        } else {
            return Promise.reject(reqConfig.response);
        }
    }
};


export default RequestInterceptor;
