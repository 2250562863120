import * as React from "react";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DomainIcon from "@mui/icons-material/Domain";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
// import { headers } from '../../../redux/actions/regActions';
import CustomInput, { CustomValidator } from "./customInput";
import CustomSelect from "./customSelect";
import { useEffect } from "react";
import { D_API, DOCS_API_V1, PY_API } from "../../../services/APIService";
// import { URI } from '../../../services/uri';
import { URI } from "../../utility/uri";
import { FormDivider, FormLayout } from "../components/layout";
import UserService from "../../../services/UserService";
import { headers } from "../../../redux/Actions/regActions";

const filter = createFilterOptions();

export default function CustomAutocomplete(props) {
  const [value, setValue] = React.useState("");
  const [open, toggleOpen] = React.useState(false);
  const [colleges, setColleges] = React.useState([]);
  let userId = sessionStorage.getItem("userId");

  const [locations, setLocations] = React.useState([]);
  const [text, setText] = React.useState("Search Locations");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const searchCollege = (value) => {
    // console.log(value)
    if (value?.length > 0) {
      axios
        .get(process.env.REACT_APP_FHIR_SERVER + `Organization?name=${value}`)
        .then((res) => {
          console.log(res, "12121772121");
          const info = res.data?.entry?.map((entry) => ({
            name: entry.resource?.name,
            id: entry.resource?.id || "",
            code: entry?.resource?.identifier?.[0]?.value,
            address: entry?.resource?.address?.[0],
          }));
          console.log(info, "332222333");

          setColleges(info || []);
        })
        .catch((err) => err);

      // PY_API.get(URI.searchUniv + value, {
      //     headers: {
      //         Authorization: `bearer ${UserService.getToken()}`
      //     }
      // }).then(res =>
      //     console.log(res, "12121212133")
      //     // setColleges(res.data.data)
      // ).catch(error => { })
    }
  };

  const handleClose = () => {
    setDialogValue({
      organisationName: "",
      addressId: "",
      description: "",
      location: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    organisationName: "",
    addressId: "",
    description: "",
    location: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValue({
      name: dialogValue.organisationName,
      location: dialogValue.location,
      organisationType: dialogValue.description,
    });
    const data = {
      date: null,
      managementText: null,
      year: null,
      index: null,
      uploadDocument: null,
      seat: null,
      collegeName: null,
      courseName: null,
      managementTeam: null,
      recognization: null,
      stateName: dialogValue.location,
      univName: dialogValue.organisationName,
      collegeId: null,
      status: "",
    };

    // console.log(data, "vij");

    let createAddUniversityOrgObj = {
      resourceType: "Organization",
      name: dialogValue.organisationName ? dialogValue.organisationName : "",
      alias: ["Colleges List"],
      address: [{ state: dialogValue.location }],
      // partOf: {
      //   reference:
      //     "Organization/18f29a952f5-eda6f3b5-d7ea-41b6-83f1-063610ac9220",
      // },
    };

    if (createAddUniversityOrgObj.name !== "") {
      axios.post(`${process.env.REACT_APP_FHIR_SERVER}${URI.organization}`, createAddUniversityOrgObj, headers())
        // axios.post(`${process.env.REACT_APP_API_ELIB_URL}${URI.addUniversities}`, createAddUniversityOrgObj)
        // D_API.post(URI.addUniversities, createAddUniversityOrgObj)
        .then((res) => {
          console.log(res, "555");
          console.log(res?.headers?.location, "uiui")
          let orgId = res?.headers?.location?.split("/")?.[7] ? res.headers?.location?.split("/")?.[7] : ""
          props.handleOrg("issuer", {
            name: dialogValue.organisationName,
            id: orgId ? orgId : "",
          });

          toggleOpen(false);
        })
        .catch((err) => {
          alert("Unable to Create Add University");
          console.log(err);
        });
    }

    // if (dialogValue.organisationName !== "") {
    //   PY_API.post(URI.addUniv, data, {
    //     headers: {
    //       Authorization: `bearer ${UserService.getToken()}`,
    //     },
    //   })
    //     .then((res) => {
    //       props.handleOrg("issuer", dialogValue.organisationName);
    //       handleClose();
    //     })
    //     .catch((error) => {});
    // }
  };

  const getLocation = (value) => {
    if (value !== "") {
      setText("No Options");
      axios
        .get(`https://dev.api.docsteth.com/elib/search_location/${value}`)
        .then((res) =>
          setLocations(
            res.data?.data?.predictions?.map((data) => data?.description)
          )
        );
    } else {
      setText("Search Location");
      setLocations([]);
    }
  };
  console.log(colleges, "colleges");
  return (
    <React.Fragment>
      {!open ? (
        <Autocomplete
          className="input_shadow select_padding"
          value={value}
          onChange={(event, newValue) => {
            // { console.log(newValue) }
            if (newValue !== null) {
              if (newValue.name === "Add Organisation") {
                toggleOpen(true);
                setDialogValue({
                  organisationName: "",
                  addressId: "",
                  description: "",
                });
              } else if (newValue && newValue.inputValue) {
                toggleOpen(true);
                setDialogValue({
                  organisationName: newValue.inputValue,
                  addressId: "",
                  description: "",
                });
              } else {
                props.handleOrg(
                  props.name,
                  newValue.univName || newValue,
                  false
                );
                setValue(newValue);
              }
            } else {
              setValue({ organisationName: "" });
            }
          }}
          onInputChange={(e) => {
            if (e !== null) {
              setValue(e.target.value);
              if (e && e.target.value !== 0) {
                searchCollege(e.target.value);
              }
            }
          }}
          filterOptions={(options, params) => {
            // console.log(params)
            const filtered = filter(options, params);
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add Organisation '${params.inputValue}'`,
              });
            }
            // { console.log(filtered) }
            return filtered;
          }}
          id="free-solo-dialog-demo"
          options={colleges}
          freeSolo
          getOptionLabel={(option) => {
            {
              console.log(option, "12121212144");
            }
            if (typeof option === "string") {
              return option;
            }
            if (option.univName) {
              // { console.log(option.univName) }
              return option.univName;
            }
            // { console.log(option) }
            return option.name || "";
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.index || option.id || option}
              value={option.univName || option.name || option}
            >
              <IconButton>
                <DomainIcon />
              </IconButton>{" "}
              {option.univName || option.name || option}
            </li>
          )}
          // sx={{ mt: 2 }}
          renderInput={(params) => {
            return (
              <CustomValidator
                {...params}
                placeholder={props.placeholder}
                value={params?.inputProps?.value}
                validators={["required"]}
                errorMessages={[`Please Select University Name`]}
              />
            );
          }}
        />
      ) : (
        <Dialog open={true} id="register" maxWidth="sm" fullWidth>
          <DialogTitle sx={{ pt: "5px!important", pb: "0px!important" }}>
            Add New Organisation
          </DialogTitle>
          <FormDivider />
          <DialogContent>
            <Grid container spacing={2}>
              <FormLayout
                name="organisationName"
                size={12}
                placeholder="Organisation Name"
                required
                errorMessages={["*Enter Organisation Name"]}
                value={dialogValue.organisationName}
                handleChange={(name, value) => {
                  setDialogValue({
                    ...dialogValue,
                    organisationName: value,
                  });
                }}
              />

              <FormLayout
                type="select"
                size={12}
                options={locations}
                name="location"
                placeholder="Location"
                getOptionLabel={(option) => (option ? option : "")}
                isOptionEqualToValue={(option, value) => option === value}
                value={dialogValue.location || ""}
                handleChange={(name, value) => {
                  setDialogValue({ ...dialogValue, location: value });
                }}
                // onChange={handlecity}
                noOptionsText={text}
                onInputChange={(e, value) => getLocation(value)}
                filterOptions={(x) => x}
              />
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button onClick={handleClose}>Cancel</Button>
                {dialogValue.organisationName !== "" ? (
                  <Button onClick={handleSubmit}>Add</Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
