import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Box,
  Tab,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import "./Feed.css";
import InputOption from "../Feed/InputOption";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Post from "../Post/Post";
import "../../utility/style.css";
import "../../Molecule/KnowledgeQuiz/QuizModal.css";
import description from "../../../images/article.svg";
import event_icon from "../../../images/event.svg";
import celebration from "../../../images/celebration.svg";
import { styled } from "@mui/material/styles";
import poll_icon from "../../../images/poll.svg";
import video from "../../../images/evideo.svg";
import CLEAR from "../../../images/clear.svg";
import clear from "../../../images/clear_meta.svg";
import bgimage from "../../../images/userfact.jpg";
// import sutucase from '../../Atom/modifiedicons/sutucase.svg'
import axios from "axios";
// import URL from '../../apiurl'
import URL from "../../apiurl";
import { useRouteMatch, useHistory, useParams } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import UserService from "../../../services/UserService";
import CircularProgress from "@mui/material/CircularProgress";
import "../../Layout/publish/publish.css";
import AddIcon from "@mui/icons-material/Add";
import CreatePost from "./createpost";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Development from "../EbmKit/development";
import Tabs from "@mui/material/Tabs";
// import { styled } from '@mui/material/styles';
import Hashtagtrending from "./Hashtagtrending";
import { useDispatch, useSelector } from "react-redux";
import { getLinkedAccounts } from "../../../redux/Actions/connectActions";
import { headers } from "../../../redux/Actions/connectActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { doRefresh } from "../../../redux/Actions/actions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LensOutlined, LensRounded } from "@mui/icons-material";
// import TabsUnstyled from '@mui/base/TabsUnstyled';

export const getLoc = async () => {
  const position = await getCurrentPosition();
  const loc = position.coords
    ? `${position.coords.latitude},${position.coords.longitude}`
    : null;
  return loc;
};
function getCurrentPosition() {
  return new Promise((resolve, reject) => {
    const Location = window.navigator && window.navigator.geolocation;
    if (Location) {
      Location.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          resolve({});
        }
      );
    }
  });
}
export const MTabs = styled(TabList)({
  "& .MuiTabs-indicator": {
    background: "none",
  },
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="unique-button-group-container">
      <IconButton
        aria-label="arrow-left"
        className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
          }`}
        onClick={() => previous()}
      >
        <KeyboardArrowLeftIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="arrow-right"
        className="carousel-arrow-button"
        onClick={() => next()}
      >
        <KeyboardArrowRightIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      style={{
        cursor: "pointer",
        color: active ? "#134265" : "#fff",
        marginTop: "10px",
      }}
      onClick={() => onClick()}
    >
      {active ? (
        <LensRounded style={{ fontSize: "14px" }} />
      ) : (
        <LensOutlined style={{ fontSize: "14px", color: "#000" }} />
      )}
    </li>
  );
};

export default function Feed({
  post,
  visible,
  create,
  view,
  selectedPostType,
  articleInfo
}) {
  // console.log("view12", view)

  const { path } = useRouteMatch();
  const [keyState, setKeyState] = useState("");
  const [value, setValue] = React.useState("1");
  const [alert, setAlert] = useState({ open: false, msg: "", post: false });
  const [load, setLoad] = useState(false);
  const [posts, setPosts] = useState([]);
  const [pageno, setPageno] = useState(0);
  const [noMore, setNoMore] = useState(true);
  const [timelineFilterPosts, setTimelineFilterPosts] = useState([]);
  const accounts = useSelector((state) => state.connect.linkedAccounts);
  console.log(posts, "all posts", pageno);
  console.log(keyState, "ddddddddddddd");
  console.log(load, "load");

  const { id } = useParams();
  const history = useHistory();

  const getRefreshFeed = useSelector(
    (state) => state.userProfile.getRefreshFeed
  );
  const orgData = useSelector((state) => state.registration.userOrg);
  const isAdmin = useSelector((state) => state.registration.isAdmin);
  console.log(orgData, "orgDatav");
  const dispatch = useDispatch();
  const limit = 25;

  useEffect(() => {
    if (getRefreshFeed === "refreshFeed") {
      setLoad(false);
      setPageno(0);
      setLoad(true);
      // getFeed()
      // getPosts()
      setTimeout(() => {
        dispatch(doRefresh(""));
      }, 2000);
    }
  }, [getRefreshFeed]);
  const handleClose = () => {
    setKeyState("");
  };

  // useEffect(async () => {
  //     setLoad(true)
  //     setPageno(0)
  // }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      setPageno(0);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (pageno === 0) {
      getPosts();
    }
  }, [pageno]);

  useEffect(() => {
    if (accounts.length === 0) dispatch(getLinkedAccounts());
  }, []);

  useEffect(() => {
    let copyOfAllPost;
    // console.log(posts,"vero")
    if (view && selectedPostType !== "ARTICLE") {
      copyOfAllPost = [...posts];
      // console.log(copyOfAllPost, "666")
      if (selectedPostType == "All") {
        setTimelineFilterPosts([...copyOfAllPost]);
      } else {
        copyOfAllPost = [...posts];
        let filterPostsList = copyOfAllPost?.filter(
          (val) => val.postType === selectedPostType
        );
        setTimelineFilterPosts([...filterPostsList]);
      }
    } else if (view && selectedPostType == "ARTICLE") {
      setTimelineFilterPosts(articleInfo ? articleInfo : [])

    } else if (view) {
      copyOfAllPost = [...posts];
      setTimelineFilterPosts([...copyOfAllPost]);
    }
  }, [view, selectedPostType, posts, articleInfo]);

  console.log(timelineFilterPosts, "timelineFilterPosts");

  // useEffect(() => {
  //     // setPageno(0);
  //     // getPosts();
  //     // setTimelineFilterPosts([])
  // }, [selectedPostType])

  console.log(posts, "roo");

  const postSuccess = (data) => {
    console.log(data, "ii", data.results, post);
    const nextdata = data.results ? data.results : data;
    setPageno(pageno + limit);
    if (pageno === 0) {
      {
        post ? setPosts(post) : setPosts(nextdata);
      }
    } else {
      console.log("first88");
      setPosts([...posts, ...nextdata]);
    }
    if (nextdata.length === 0 || nextdata.length < limit) {
      setNoMore(false);
    }
    setAlert({ open: false, msg: "", post: false });
    setLoad(false);
  };
  console.log(post, "dskjfshdfkjshkj");

  console.log(posts, "settingposts");
  const postFailure = () => {
    setLoad(false);
    setAlert({ open: true, msg: "Internal Server Error.Try after sometime" });
  };

  const postAPIS = async (key) => {
    const APIS = {
      timeline: `${URL.getPosts}/${UserService.getUserId()}`,
      feedPosts: URL.feed,
      // orgPosts: id?.length >= 3 ? `${URL.findPostByPageId}/${id}` : ""
      orgPosts: `${URL.findPostByPageId}/${id}`,
    };

    await axios
      .get(`${APIS[key]}?limit=${limit}&offset=${pageno}`, headers())
      .then((res) => {
        console.log("first", res.data);
        postSuccess(res.data);
      })
      .catch(() => {
        postFailure();
      });
  };

  // const getPosts = async () => {
  //     if (path.includes('/myprofile')) {
  //         await postAPIS('timeline')
  //     }
  //     else if (path.includes('/admin/organization')) {
  //         getOrgFeed()
  //     }
  //     else {
  //         getFeed()
  //     }
  // }
  const getPosts = async () => {
    try {
      if (path.includes("/myprofile")) {
        await postAPIS("timeline");
        console.log("first111");
      } else if (path.includes("/admin/organization")) {
        console.log("first222");
        await getOrgFeed();
      } else {
        console.log("first333");
        await getFeed();
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  // const getFeed = async () => {
  //     await postAPIS('feedPosts')
  // }
  const getFeed = async () => {
    try {
      const response = await postAPIS("feedPosts");
      // Optionally process or manipulate the response data here
      return response; // Return the response data if needed
    } catch (error) {
      console.error("Error fetching feed posts:", error);
      throw error; // Rethrow the error to handle it further up the call stack
    }
  };

  const getOrgFeed = async () => {
    await postAPIS("orgPosts");
  };

  console.log(timelineFilterPosts, "timelineFilterPosts");
  // const getOrgFeed = async () => {
  //     try {
  //         const response = await postAPIS('orgPosts');
  //         // Optionally process or manipulate the response data here
  //         return response; // Return the response data if needed
  //     } catch (error) {
  //         console.error('Error fetching organization feed posts:', error);
  //         throw error; // Rethrow the error to handle it further up the call stack
  //     }
  // };

  const handlePopup = (key) => {
    setKeyState(key);
  };

  const handleNavigate = (e) => {
    console.log(e);
    e.preventDefault();
    history.replace("/newarticle");
    // document.location.reload();
  };

  return (
    <div
    // className="feed"
    >
      {keyState ? (
        <CreatePost
          keystate={keyState}
          handleClose={handleClose}
          getPosts={getPosts}
          getFeed={getFeed}
          getOrgFeed={getOrgFeed}
        />
      ) : (
        ""
      )}

      {/*----------------- Desktop------------------------- */}

      {!create ? (
        <Grid container sx={{}}>
          <Grid item md={12} xs={12} >
            <Box>
              <TabContext value={value}>
                {console.log(posts, "viesss")}
                {!view ? (
                  <div className="feed__inputContainer border_img" style={{ display: "flex", alignItems: 'center' }}>
                    <MTabs
                      id="tab_design"
                      aria-label="lab API tabs example"
                      value={value}
                      onChange={(e, newValue) => setValue(newValue)}
                      textColor="inherit"
                      scrollButtons={false}
                      sx={{ marginTop: "7px", marginLeft: "8px", display: "flex", alignItems: "center", }}
                    >
                      <Tab
                        className={`${value == 1 ? "tab_circle" : "tab_circle1"}`}
                        sx={{ fontSize: "17px", textTransform: "capitalize" }}
                        value="1"
                        label="suggested"
                      />
                      <Tab
                        className={`${value == 1 ? "tab_circle2" : "tab_circle3"
                          }`}
                        sx={{ fontSize: "17px", textTransform: "capitalize" }}
                        value="2"
                        label="trending"
                      />
                    </MTabs>
                  </div>
                ) : (
                  ""
                )
                }
                <TabPanel value="1" sx={{ p: "0px" }}>
                  {!view ? (
                    <div className="feed_desktop" style={{ width: "100%" }} >
                      <div className="feed__inputContainer border_img">
                        <div
                          className="feed__input cursor"
                          onClick={() => {
                            handlePopup("text");
                          }}
                        >
                          <p>Write Something...</p>
                        </div>
                        <div className="feed__inputOptions border__rr border__lr">
                          <div className="newcontact-fieldheader">
                            <div
                              className="feed__inputOption justify-between"
                              style={{ marginLeft: "0px", width: "70%" }}
                            >
                              <InputOption
                                src={celebration}
                                alt="Celebrate"
                                title="Celebrate"
                                handleClick={() => handlePopup("celebrate")}
                              />
                              <InputOption
                                src={poll_icon}
                                title="Create Poll"
                                alt="Poll"
                                handleClick={() => handlePopup("poll_form")}
                              />
                              {/* <InputOption src={event_icon} title="Create Event" alt="Event"
                                                            handleClick={
                                                                () => handlePopup('event_form')}
                                                        /> */}
                              <InputOption
                                src={description}
                                title="Write Article"
                                alt="Article"
                                handleClick={(event) => {
                                  handleNavigate(event);
                                }}

                              />
                            </div>
                            <div className="">
                              <Button
                                className="add-option evnt-btn"
                                sx={{ borderRadius: "10px!important" }}
                                onClick={() => {
                                  handlePopup("text");
                                }}
                              >
                                New Post
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {load ? (
                    <div
                      style={{ height: "300px" }}
                      className="elib-right__content"
                    >
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    <>
                      {!view ? (
                        <InfiniteScroll
                          dataLength={posts.length}
                          next={getPosts}
                          hasMore={noMore}
                          style={{ overflow: "hidden" }}
                          scrollThreshold="75%"
                          loader={
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress disableShrink />
                            </div>
                          }
                          endMessage={
                            <p style={{ textAlign: "center", padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>
                              {" "}
                              No more posts
                            </p>
                          }
                        >
                          {posts?.map((item, index) => (
                            <div key={index}>
                              <Post
                                post={item}
                                getPosts={() => {
                                  setAlert({
                                    open: true,
                                    msg: "Deleting...",
                                    post: true,
                                  });
                                  getPosts();
                                }}
                              />
                            </div>
                          ))}
                        </InfiniteScroll>
                      ) : (
                        <Box sx={{}}>
                          <Card className="mt1" sx={{ width: "99.9%", boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                            <div style={{}}>
                              {/* <div className="feed__inputContainer border_img"> */}
                              <div
                                className="feed__input cursor"
                                onClick={() => {
                                  handlePopup("text");
                                }}
                              >
                                <p>Write Something...</p>
                              </div>
                              <div className="feed__inputOptions border__rr border__lr">
                                <div className="newcontact-fieldheader">
                                  <div
                                    className="feed__inputOption justify-between"
                                    style={{
                                      marginLeft: "0px",
                                      width: "70%",
                                    }}
                                  >
                                    <InputOption
                                      src={celebration}
                                      alt="Celebrate"
                                      title="Celebrate"
                                      handleClick={() =>
                                        handlePopup("celebrate")
                                      }
                                    />
                                    <InputOption
                                      src={poll_icon}
                                      title="Create Poll"
                                      alt="Poll"
                                      handleClick={() =>
                                        handlePopup("poll_form")
                                      }
                                    />
                                    {/* <InputOption src={event_icon} title="Create Event" alt="Event"
                                                            handleClick={
                                                                () => handlePopup('event_form')}
                                                        /> */}
                                    <InputOption
                                      src={description}
                                      title="Write Article"
                                      alt="Article"
                                      handleClick={(event) => {
                                        handleNavigate(event);
                                      }}
                                    />
                                  </div>
                                  <div className="">
                                    <Button
                                      className="add-option evnt-btn"
                                      sx={{ borderRadius: "10px!important" }}
                                      onClick={() => {
                                        handlePopup("text");
                                      }}
                                    >
                                      New Post
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 20,
                                }}
                              >
                                {/* <h6
                                  style={{
                                    color: "#000",
                                    fontSize: 18,
                                    fontWeight: 500,
                                    marginTop: 5,
                                  }}
                                >
                                  Timeline
                                </h6> */}
                                {/* {isAdmin === "PERMIT" && (
                                  <IconButton
                                    aria-label="add"
                                    size="medium"
                                    color="primary"
                                    onClick={() => handlePopup("text")}
                                    style={{ margin: "0px" }}
                                  >
                                    <AddCircleRoundedIcon fontSize="inherit" />
                                  </IconButton>
                                )} */}
                              </div>
                            </div>
                          </Card>
                          <Card className="ph2 pt1 mt2" sx={{
                            // border: "1px solid red",
                            width: "95.2%",
                            borderRadius: "10px", height: { sm: '100%', md: "100%", lg: "100%" },
                            marginBottom: { xs: '60px', sm: "60px", md: "0px" },
                            boxShadow: "0px 0px 1.6px #888",
                          }}>
                            < h6
                              style={{
                                color: "#000",
                                fontSize: 18,
                                fontWeight: 500,
                                marginTop: 5,
                              }}
                            >
                              Timeline
                            </h6>
                            {console.log(timelineFilterPosts, "timelineFilterPosts")}
                            {timelineFilterPosts?.map((post, index) => (
                              <div key={index} style={{ marginTop: "10px", marginRight: "10px" }}>
                                <Post
                                  fromAdminOrg="adminOrg"
                                  post={post}
                                  getPosts={() => {
                                    setAlert({
                                      open: true,
                                      msg: "Deleting...",
                                      post: true,
                                    });
                                    getPosts();
                                  }}
                                />
                              </div>
                            ))}
                          </Card>
                        </Box>

                        // </Card>
                        // <Card elevation={0}>
                        //     <CardContent>
                        //         <div
                        //             style={{
                        //                 display: "flex",
                        //                 justifyContent: "space-between",
                        //                 alignItems: "center",
                        //             }}
                        //         >
                        //             <div
                        //                 style={{
                        //                     display: "flex",
                        //                     alignItems: "center",
                        //                     gap: 20,
                        //                 }}
                        //             >
                        //                 <h6
                        //                     style={{
                        //                         color: "#000",
                        //                         fontSize: 18,
                        //                         fontWeight: 500,
                        //                         marginTop: 5,
                        //                     }}
                        //                 >
                        //                     Timeline
                        //                 </h6>
                        //                 <IconButton
                        //                     aria-label="add"
                        //                     size="medium"
                        //                     color="primary"
                        //                     onClick={() => handlePopup('text')}
                        //                     style={{ margin: "0px" }}
                        //                 >
                        //                     <AddCircleRoundedIcon fontSize="inherit" />
                        //                 </IconButton>
                        //             </div>
                        //         </div>
                        //         <div>
                        //             <Carousel

                        //                 swipeable={false}
                        //                 draggable={false}
                        //                 showDots={true}
                        //                 responsive={responsive}
                        //                 ssr={true}
                        //                 keyBoardControl={true}
                        //                 containerClass="carousel-container-3"
                        //                 removeArrowOnDeviceType={["tablet", "mobile",]}
                        //                 // customButtonGroup={<ButtonGroup />}
                        //                 renderButtonGroupOutside={true}
                        //                 customDot={<CustomDot />}
                        //                 arrows={false}
                        //             >
                        //                 {posts?.map((post, index) => (
                        //                     <div key={index} >
                        //                         <Post
                        //                             fromAdminOrg="adminOrg"
                        //                             post={post}
                        //                             getPosts={() => { setAlert({ open: true, msg: 'Deleting...', post: true }); getPosts() }} />
                        //                     </div>))}
                        //             </Carousel>
                        //         </div>
                        //     </CardContent>
                        //     {/* <Divider
                        //         sx={{
                        //             borderBottomWidth: 5,
                        //             borderRadius: 20,
                        //             color: "#F7F7F7",
                        //         }}
                        //     /> */}
                        //     {/* <CardActions>
                        //         <Button
                        //             variant="contained"
                        //             sx={{
                        //                 backgroundColor: "transparent",
                        //                 color: "#A3A3A3",
                        //                 boxShadow: "none",
                        //                 textTransform: "capitalize",
                        //                 marginX: "auto",
                        //                 alignItems: "center",
                        //                 "&:hover": {
                        //                     backgroundColor: "transparent",
                        //                 },
                        //             }}
                        //         >
                        //             Show All Details
                        //             <ArrowRightAltIcon />
                        //         </Button>
                        //     </CardActions> */}
                        // </Card>
                      )}
                    </>
                  )}

                  {/* <div
                                        className="button__add_icon"
                                        onClick={() => {
                                            handlePopup("text");
                                        }}
                                    >
                                        <div className="addicon">
                                            <div className="fabdetail">
                                                <span><AddIcon style={{ marginBottom: '-5px' }} /></span>
                                            </div>
                                        </div>
                                    </div> */}
                </TabPanel>
                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                  <Grid>
                    <Hashtagtrending />
                  </Grid>
                  {/* <Development /> */}
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      ) : (
        ""
      )
      }

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={alert.post ? null : 2000}
        open={alert.open}
        onClose={() => setAlert({ open: false, msg: "", post: false })}
        message={alert.msg}
      />
    </div >
  );
}
