import React, { useEffect, useState } from "react";
import LinkAccountImg from '../../../../../images/linkaccount.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Autocomplete, Avatar, Box, Button, Checkbox, DialogActions, FormControlLabel, FormGroup, InputAdornment, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getLinkedAccounts1, getLinkedAccountsOrg, headers } from "../../../../../redux/Actions/connectActions";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CloseBtn, CreateBtn, Inputs } from "../../../LinkAccount/linkaccount";
import Dropdown from '../../../../../Components/Fields/CssTextField'
import DialogBox from "../../../../DialogBox/dialog";
import EditAccount from "../../../LinkAccount/editAccount";
import { FaSpotify, FaInstagramSquare } from 'react-icons/fa'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FiEdit3 } from "react-icons/fi";
import Layout from "../layout";
import UserService from "../../../../../services/UserService";
import { GrGoogle } from "react-icons/gr";
import DeleteIcon from '@mui/icons-material/Delete';



const serviceProfile = ['Medxperts', 'Practo', 'Others']
const providerOptions = ["linkedin", "twitter", "Facebook", "instagram", "whatsapp", "spotify", "Google", "Others"]

const Twitter = () => <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "25px", height: "25px", paddingRight: "7px" }} />
const Facebook = () => <FacebookIcon style={{ color: '#385898', width: "25px", height: "25px", paddingRight: "7px" }} />
const Google = () => <GrGoogle style={{ color: '#385898', width: "20px", height: "20px", paddingRight: "7px" }} />
const LinkedIn = () => <LinkedInIcon style={{ color: '#007DCD', width: "25px", height: "25px", paddingRight: "7px" }} />
const WhatsApp = () => <WhatsAppIcon style={{ color: 'green', width: "25px", height: "25px", paddingRight: "7px" }} />
const Spotify = () => <FaSpotify style={{ color: '#1DB954', width: "25px", height: "25px", paddingRight: "7px" }} />
const Insta = () => <FaInstagramSquare style={{ width: "25px", height: "25px", paddingRight: "7px" }} />

export const comp = {
    linkedin: <LinkedIn />,
    whatsapp: <WhatsApp />,
    spotify: <Spotify />,
    facebook: <Facebook />,
    google: <Google />,
    instagram: <Insta />,
    twitter: <Twitter />,
    Private: <VisibilityOffOutlinedIcon />,
    Public: <VisibilityOutlinedIcon />
}

export const redirect_uri = (type, id) => {
    const url = type === 'post' ? process.env.REACT_APP_DOCSTETH : `${process.env.REACT_APP_DOCSTETH}/shareprofile`
    return {
        linkedin: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78v81nxtfzvqns&redirect_uri=${url}&scope=r_basicprofile%20r_emailaddress%20w_member_social&state=de71b7e4-6610-47bd-9dd1-1b1886d9abc9_${type}-${id}`,
        twitter: `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=b2M2OHdXUEdfZTRSMGhadzhoaXk6MTpjaQ&redirect_uri=${url}&scope=tweet.read%20tweet.write%20users.read%20offline.access%20follows.read%20follows.write&state=da8e1439-fb1a-447e-b481-cb5e5028e521_${type}-${id}&code_challenge=challenge&code_challenge_method=plain`
    }
}

const CellData = ({ name }) => {
    return <TableCell>{name}</TableCell>
}
export const InputsCode = ({ options, value, handleChange, placeholder, hide, ...props }) => {

    console.log(options, "options11")
    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => typeof option === 'string' ? option : option?.page || option} // Render page if option is an object
            isOptionEqualToValue={(option, value) => option?.page === value?.page || option === value}
            value={value}
            onChange={handleChange}
            filterOptions={(x) => x}
            renderOption={(props, option) => (
                <li {...props}>

                    <span className="capitalize">{typeof option === 'string' ? option : option?.page}</span> {/* Render page if option is an object */}
                </li>
            )}
            sx={{ '& input': { textTransform: 'capitalize' } }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...props}
                    margin="dense"
                    sx={{
                        borderRadius: '7px',
                        ".MuiOutlinedInput-root": { padding: '4px' }, boxShadow: 1
                    }}
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: !hide && value && typeof value !== 'string' ? (
                            <span style={{ marginRight: 8 }}>

                            </span> // Render page if value is an object
                        ) : null
                    }}
                />
            )}
        />
    )
}

const AccountsListOrg = ({ linkedAcc, getAccounts, displayLinkedIcons, orgData, ...props }) => {

    let header = ["Provider", "Profile/Page", "Purpose", "Visibility", "Status", "", ""]
    const [open, setOpen] = useState(0)

    console.log(linkedAcc, "linkedAcc")


    const handleDialog = () => {
        setOpen(0)
    }

    const deleteAcc = () => {
        const token = UserService.getToken();
        const data = {
            platform: open
        };

        console.log("Data:", data, "Token:", token); // Log to ensure data and token are retrieved correctly

        if (token) {
            axios.delete(`${process.env.REACT_APP_API_URL_PYI}/deleteLinkedAccountsDocstethPage/${orgData?.id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                },
                data: data // Include data in the request body
            })
                .then(res => {
                    console.log("Delete successful:", res.data); // Optionally log the response
                    getAccounts(); // Assuming getAccounts is defined elsewhere
                    handleDialog();
                })
                .catch(error => {
                    console.error("Delete failed:", error); // Log any errors
                });
        } else {
            console.error("Token not available");
        }
    };

    return (
        <>
            <DialogBox open={open !== 0} handleDialog={handleDialog} title="Delete Account" Content={<p>Do you want to delete account</p>}
                Actions={
                    <>
                        <CloseBtn name="No" onClick={handleDialog} />
                        <CreateBtn onClick={deleteAcc} name="Yes" />
                    </>
                } />
            <Table className="link_border">
                <TableHead>
                    {header.map((key, i) => <React.Fragment key={i}><CellData name={key} /></React.Fragment>)}
                </TableHead>
                <TableBody>
                    {console.log(linkedAcc, "displayLinkedIcons1")}
                    {linkedAcc.length > 0 ? linkedAcc.map(acc =>
                        <TableRow key={acc.id}>
                            <CellData name={<div className="d-flex e-align capitalize">{comp[acc.platform]} <p>{acc.platform}</p></div>} />
                            <CellData name={<a className="d-flex e-align capitalize" href={acc.socialAccountLink} target="_blank"><Avatar src={acc?.profile} />{acc?.page || acc?.locationName || ""}</a>} />
                            <CellData name={acc.purpose} />
                            <CellData name={<div className="d-flex e-align capitalize">{comp[acc.visibility]}&nbsp;<p>{acc.visibility}</p></div>} />
                            <CellData name={acc.status} />
                            <TableCell><FiEdit3 style={{ fontSize: '20px' }} onClick={() => props.handleEdit(acc)} /></TableCell>
                            {/* <TableCell className="cursor"><BackspaceOutlinedIcon onClick={() => setOpen(acc?.platform)} /></TableCell> */}
                            <TableCell className="cursor"><DeleteIcon sx={{ color: "red" }} onClick={() => setOpen(acc?.platform)} /></TableCell>
                        </TableRow>
                    ) : <TableCell colSpan={12} align="center">No Linked Accounts</TableCell>}
                </TableBody>
            </Table>

            <DialogActions>
                <CloseBtn name="Close" onClick={props.handleDialog} />
                <CreateBtn onClick={props.handleAcc} name="Add New" />
            </DialogActions>
        </>
    )
}
// const AddAccountOrg = ({ close, orgData, googleDetails, linkedAcc, getAccounts }) => {

//     let formData = { purpose: '', accProvider: '', providerName: '', profile: '', AccountNumber: '', Location: null, googleUser: null }
//     const [state, setState] = useState(formData)

//     const params = useParams()
//     const history = useHistory()
//     const location = useLocation()

//     const accounts = useSelector(state => state.connect.linkedAccounts)
//     console.log(location, "paramsssss", params, googleDetails)

//     const handleChange = (name, value) => {
//         console.log(name, value)
//         if (name === 'purpose') setState({ ...formData, [name]: value })
//         else setState({ ...state, [name]: value })
//     }
//     const handlePurposeChange = (event) => {
//         const selectedPurpose = event.target.name;
//         setState((prevState) => ({ ...prevState, purpose: selectedPurpose }));
//     };

//     const linkAccount1 = () => {
//         let data = {
//             purpose: state.purpose,
//             providerName: state.providerName
//         }
//         console.log(data, "888888")
//         axios.get(`https://dev.api.docsteth.com/feed/FacebookOauth`)
//             .then(res => {
//                 console.log(res.data)
//             })
//             .catch(err => console.log(err))

//     }

//     const getOptions = () => {
//         if (state.purpose && state.purpose === 'Facebook') {
//             return accounts.flatMap(account => account.pages);
//         } else {
//             return [];
//         }
//     };

//     const linkAccount = () => {

//         if (state.purpose === "Facebook") {
//             const data = {

//                 docstethPageId: orgData?.id
//             };
//             console.log(data, "sssssssffff", state)
//             axios.post(`https://dev.api.docsteth.com/feed/linkFacebookDocstethPages/${state.providerName?.id}`,
//                 data, {
//                 headers: {
//                     "Authorization": `bearer ${UserService.getToken()}`
//                 }
//             }
//             ).then(res => {
//                 console.log(res)
//                 getAccounts()
//                 close()

//             })
//                 .catch(err => console.log(err))
//         }
//         else if (state.purpose === "Google") {
//             console.log("hhhhhhhhhhhhhhhhh", state)
//             let dataGoogle = {
//                 "docstethPageId": orgData?.id,
//                 "accountId": googleDetails?.accounts?.[0]?.name?.split('/')[1],
//                 "accountName": googleDetails?.accounts?.[0]?.accountName,
//                 "locationName": state?.Location?.title,
//                 "profileId": state?.AccountNumber
//             }
//             // axios.post(process.env.REACT_APP_API_URL_PYI + `/linkGoogleDocstethPage`,
//             //     dataGoogle,
//             //     {
//             //         headers: {
//             //             "Authorization": `bearer ${UserService.getToken()}`
//             //         }
//             //     }
//             // ).then(res => {
//             //     console.log(res)
//             //     getAccounts()
//             //     close()

//             // })
//         }

//     };


//     return (
//         <Box>
//             <ValidatorForm onSubmit={linkAccount}>
//                 <>
//                     <Inputs placeholder="Account Purpose"
//                         handleChange={(e, value) => handleChange('purpose', value)}
//                         // options={['Google Business Account', 'Service Profile']}
//                         options={providerOptions.filter(op => !linkedAcc.some(acc => acc.platform === op.toLowerCase()))}
//                         // options={providerOptions}
//                         value={state.purpose}
//                         validators={['required']}
//                         errorMessages={['Please select account purpose']}
//                     />

//                     {state.purpose === "Facebook" &&
//                         <Inputs
//                             placeholder="Select Account Provider"
//                             handleChange={(e, value) => handleChange('providerName', value)}
//                             options={getOptions()}
//                             value={state.providerName}
//                             validators={['required']}
//                             errorMessages={['Please select account provider']}
//                         />
//                     }

//                     {
//                         state.purpose === "Google" &&
//                         <>
//                             <Autocomplete
//                                 name="Location"
//                                 value={state.Location}
//                                 onChange={(e, value) => handleChange('Location', value)}
//                                 // onChange={handleChange}
//                                 id="controllable-states-demo"
//                                 getOptionLabel={option => option.title || option}
//                                 options={googleDetails?.accounts?.[0]?.locations || []}
//                                 sx={{ width: '100%' }}

//                                 renderInput={(params) => <TextField
//                                     // helperText="error"
//                                     // required
//                                     validators={['required']}
//                                     errorMessages={["Select Location"]}
//                                     sx={{
//                                         borderRadius: '7px',
//                                         ".MuiOutlinedInput-root": { padding: '8px' }, boxShadow: 1,
//                                         mt: "10px"
//                                     }}
//                                     {...params} placeholder="Select Location" />}
//                             />

//                             <TextField
//                                 name="AccountNumber"
//                                 type="number"
//                                 placeholder="Enter Your Business Profile ID"
//                                 value={state.AccountNumber}
//                                 onChange={(e, value) => handleChange('AccountNumber', e.target.value)}
//                                 errorMessages={["Enter Your Business Profile ID"]}
//                                 sx={{
//                                     borderRadius: '7px',
//                                     mt: "10px",
//                                     width: '100%',
//                                     '.MuiOutlinedInput-input': { padding: '15px 14px' }

//                                 }} />

//                         </>
//                     }
//                     {state.providerName && (state.providerName !== 'Others' ?
//                         <div className="d-flex">

//                         </div>
//                         :
//                         <>
//                             <Dropdown
//                                 margin="dense"
//                                 placeholder='Account Provider / Application Name'
//                                 variant="outlined"
//                                 value={state.accProvider}
//                                 fullWidth
//                                 onChange={e => handleChange('accProvider', e.target.value)}
//                                 validators={['required']}
//                                 errorMessages={['Please enter Account Provider / Application Name']}
//                             />
//                             <Dropdown
//                                 margin="dense"
//                                 placeholder='Your Profile / Page URL'
//                                 variant="outlined"
//                                 value={state.profile}
//                                 fullWidth
//                                 onChange={e => handleChange('profile', e.target.value)}
//                                 validators={['required']}
//                                 errorMessages={['Please enter Your Profile / Page URL']}
//                             />
//                         </>
//                     )
//                     }
//                 </>

//                 <DialogActions>
//                     <CloseBtn name="Close" onClick={close} />
//                     <CreateBtn type="submit" name={"Link"} />
//                 </DialogActions>
//             </ValidatorForm>
//         </Box>
//     )
// }


const AddAccountOrg = ({ close, orgData, googleDetails, linkedAcc, getAccounts }) => {
    let formData = { purpose: '', accProvider: '', providerName: '', profile: '', AccountNumber: '', Location: null, googleUser: null }
    const [state, setState] = useState(formData);
    const [errors, setErrors] = useState({ Location: false, AccountNumber: false });

    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const accounts = useSelector(state => state.connect.linkedAccounts);

    const handleChange = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));

        // Update validation state
        if (name === 'Location' && value === null) {
            setErrors(prevErrors => ({ ...prevErrors, Location: true }));
        } else if (name === 'Location') {
            setErrors(prevErrors => ({ ...prevErrors, Location: false }));
        }
        if (name === 'AccountNumber' && value === '') {
            setErrors(prevErrors => ({ ...prevErrors, AccountNumber: true }));
        } else if (name === 'AccountNumber') {
            setErrors(prevErrors => ({ ...prevErrors, AccountNumber: false }));
        }
    };

    const validateFields = () => {
        let valid = true;
        let newErrors = { Location: false, AccountNumber: false };

        if (state.purpose === 'Google') {
            if (!state.Location) {
                newErrors.Location = true;
                valid = false;
            }
            if (!state.AccountNumber) {
                newErrors.AccountNumber = true;
                valid = false;
            }
        }

        setErrors(newErrors);
        return valid;
    };

    const linkAccount = () => {
        // Validate fields
        const isValid = validateFields();
        if (!isValid) {
            // If validation fails, prevent API call
            return;
        }

        if (state.purpose === "Facebook") {
            const data = {
                docstethPageId: orgData?.id
            };
            axios.post(`https://dev.api.docsteth.com/feed/linkFacebookDocstethPages/${state.providerName?.id}`,
                data, {
                headers: {
                    "Authorization": `bearer ${UserService.getToken()}`
                }
            }
            ).then(res => {
                getAccounts();
                close();
            })
                .catch(err => console.log(err));
        } else if (state.purpose === "Google") {
            let dataGoogle = {
                "docstethPageId": orgData?.id,
                "accountId": googleDetails?.accounts?.[0]?.name?.split('/')[1],
                "accountName": googleDetails?.accounts?.[0]?.accountName,
                "locationName": state?.Location?.title,
                "profileId": state?.AccountNumber
            };
            axios.post(process.env.REACT_APP_API_URL_PYI + `/linkGoogleDocstethPage`,
                dataGoogle,
                {
                    headers: {
                        "Authorization": `bearer ${UserService.getToken()}`
                    }
                }
            ).then(res => {
                getAccounts();
                close();
            });
        }
    };

    const getOptions = () => {
        if (state.purpose === 'Facebook') {
            return accounts.flatMap(account => account.pages);
        } else {
            return [];
        }
    };

    return (
        <Box>
            <ValidatorForm onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission
                linkAccount(); // Call the linkAccount function with validation
            }}>
                <>
                    <Inputs
                        placeholder="Account Purpose"
                        handleChange={(e, value) => handleChange('purpose', value)}
                        options={providerOptions.filter(op => !linkedAcc.some(acc => acc.platform === op.toLowerCase()))}
                        value={state.purpose}
                        validators={['required']}
                        errorMessages={['Please select account purpose']}
                    />

                    {state.purpose === "Facebook" &&
                        <InputsCode
                            placeholder="Select Account Provider"
                            handleChange={(e, value) => handleChange('providerName', value)}
                            options={getOptions()}
                            value={state.providerName}
                            validators={['required']}
                            // style={{ borderRadius: '9px' }}
                            // sx={{
                            //     '.MuiOutlinedInput-root': {
                            //         borderRadius: '20px'
                            //     },
                            //     '.MuiTextField-root': {
                            //         borderRadius: '20px'
                            //     }

                            // }}
                            errorMessages={['Please select account provider']}
                        />
                    }

                    {state.purpose === "Google" &&
                        <>
                            <Autocomplete
                                name="Location"
                                value={state.Location}
                                onChange={(e, value) => handleChange('Location', value)}
                                id="controllable-states-demo"
                                getOptionLabel={option => option.title || option}
                                options={googleDetails?.accounts?.[0]?.locations || []}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    placeholder="Select Location"
                                    error={errors.Location}
                                    helperText={errors.Location ? "Select Location" : ""}
                                    sx={{
                                        borderRadius: '12px',
                                        ".MuiOutlinedInput-root": { padding: '3px', borderRadius: '12px', }, boxShadow: errors.Location ? "" : 1,
                                        mt: "6px",
                                    }}
                                />
                                }
                            />

                            <TextField
                                name="AccountNumber"
                                type="number"
                                placeholder="Enter Your Business Profile ID"
                                value={state.AccountNumber}
                                onChange={(e) => handleChange('AccountNumber', e.target.value)}
                                error={errors.AccountNumber}
                                helperText={errors.AccountNumber ? "Enter Your Business Profile ID" : ""}
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '10px',
                                    },

                                    mt: 2,
                                    width: '100%',
                                    '.MuiOutlinedInput-input': { padding: '10px 14px', borderRadius: '18px' }
                                }}
                            />
                        </>
                    }

                    {state.providerName && (state.providerName !== 'Others' ?
                        <div className="d-flex"></div> :
                        <>
                            <Dropdown
                                margin="dense"
                                placeholder='Account Provider / Application Name'
                                variant="outlined"
                                value={state.accProvider}
                                fullWidth
                                onChange={e => handleChange('accProvider', e.target.value)}
                                validators={['required']}
                                errorMessages={['Please enter Account Provider / Application Name']}
                            />
                            <Dropdown
                                margin="dense"
                                placeholder='Your Profile / Page URL'
                                variant="outlined"
                                value={state.profile}
                                fullWidth
                                onChange={e => handleChange('profile', e.target.value)}
                                validators={['required']}
                                errorMessages={['Please enter Your Profile / Page URL']}
                            />
                        </>
                    )}
                </>
                <DialogActions>
                    <CloseBtn name="Close" onClick={close} />
                    <CreateBtn type="submit" name={"Link"} />
                </DialogActions>
            </ValidatorForm>
        </Box>
    );
};



const LinkAccountOrg = () => {

    const orgData = useSelector((state) => state.registration.userOrgData);
    const accounts = useSelector(state => state.connect.linkedAccountsOrg)
    const accountsforOpt = useSelector(state => state.connect.linkedAccounts)

    console.log(accounts, "accountsforOpt", accountsforOpt)
    const [open, setOpen] = useState(false)
    const [acc, setAcc] = useState(false)
    const [linkedAcc, setLinkedAcc] = useState([])
    const [editData, setEditData] = useState(null)
    const [anchorEl, setAnchorEl] = useState({});
    const [displayLinkedIcons, setDisplayLInkedIcons] = useState([])
    const [googleDetails, setGoogleDetails] = useState([])
    const dispatch = useDispatch()

    const openPop = Boolean(anchorEl);
    console.log(linkedAcc, "linkedAcc")


    const [anchorEls, setAnchorEls] = useState({});


    useEffect(() => {
        if (orgData?.id) {
            getListLinked()
        }
    }, [orgData?.id])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_PYI + `/getGoogleAccounts`,
            {
                headers: {
                    "Authorization": `bearer ${UserService.getToken()}`
                }
            }
        )
            .then(res => {
                console.log(res)
                setGoogleDetails(res.data || [])
            }).catch(err => console.log(err))
    }, [])
    useEffect(() => {
        const fetchAccounts = async () => {
            const accountsData = await dispatch(getLinkedAccounts1());
            // setAccounts(accountsData);
            console.log(accountsData, "accountsData")
        };
        fetchAccounts();
    }, []);

    const getListLinked = () => {
        axios.get(process.env.REACT_APP_API_URL_PYI + `/getLinkedAccountsDocstethPage/${orgData?.id}`,
            {
                headers: {
                    "Authorization": `bearer ${UserService.getToken()}`
                }
            }
        )
            .then(res => {
                console.log(res, "9090saiii")
                setDisplayLInkedIcons(res?.data || [])
            }).catch(err => console.log(err))
    }

    const handlePopoverOpen = (event, platform) => {
        setAnchorEls(prevState => ({
            ...prevState,
            [platform]: event.currentTarget
        }));
    };

    const handlePopoverClose = (platform) => {
        console.log(`Closing popover for platform: ${platform}`);

        setAnchorEls(prevState => ({
            ...prevState,
            [platform]: null
        }));
    };



    console.log(accounts, "2222344232ed")

    const handleDialog = () => {
        setOpen(prev => !prev)
    }

    const handleAcc = () => {
        setAcc(prev => !prev)
    }

    useEffect(() => {
        setLinkedAcc(accounts)
    }, [accounts])

    useEffect(() => {
        // if (accounts.length === 0)
        getAccounts()
    }, [orgData])


    const getAccounts = () => {
        if (orgData?.id) {
            dispatch(getLinkedAccountsOrg(orgData?.id))
        }
    }


    const handleEdit = (data) => {
        setEditData(data)
    }

    return (
        <div className="border mt-10" style={{ boxShadow: "0px 0px 1.6px #888", borderRadius: "10px", backgroundColor: "white", }}>
            <DialogBox open={open} handleDialog={() => { }} headerStyle={{ backgroundColor: '#EDDAFD' }} Title={<p className="font-bold">{acc ? 'Link New Accounts' : editData ? 'Edit Account' : 'Link Accounts'}</p>} width={acc ? "sm" : "md"}
                style={{ paddingBottom: '0px' }}
                Content={acc ? <AddAccountOrg close={handleAcc} orgData={orgData} googleDetails={googleDetails} linkedAcc={linkedAcc} getAccounts={getAccounts} /> :
                    editData ? <EditAccount data={editData} close={handleEdit} getAccounts={getAccounts} /> :
                        <AccountsListOrg
                            linkedAcc={linkedAcc}
                            getAccounts={getAccounts}
                            handleDialog={handleDialog}
                            handleAcc={handleAcc}
                            handleEdit={handleEdit}
                            orgData={orgData}
                        />}
            />
            <Layout HeadIcon={<img src={LinkAccountImg} alt="Accounts" />}
                Title="Link Accounts" handleAdd={() => {
                    handleDialog();
                    handleAcc()
                }} handleView={handleDialog}
            />
            {console.log(linkedAcc, "linkedAcc111111")}
            {linkedAcc?.length > 0 ?
                <>
                    <div className='pl-3'>
                        <h3 className='fw__normal' style={{ color: "#000000", fontSize: "16px" }}>Social Profiles</h3>
                    </div>
                    <div className='mt-10 pl-3 d-flex e-align sidebar__avatar'>

                        {linkedAcc.map((acc, i) => (
                            <React.Fragment key={i}>
                                <Typography
                                    aria-owns={anchorEls[acc?.platform] ? acc?.platform : undefined}
                                    aria-haspopup="true"
                                // onClick={(event) => handlePopoverOpen(event, acc?.platform)}
                                // onMouseLeave={() => handlePopoverClose(acc.platform)}
                                >
                                    {comp[acc.platform]}
                                </Typography>


                            </React.Fragment>
                        ))}
                    </div>
                </> : <p className='pl-3 sidebar__pb'>No Linked Accounts</p>}
        </div>
    )
}
export default LinkAccountOrg