import { Divider, Grid } from "@mui/material";
import _ from "lodash";

export function fetchMobile(telecom = []) {
  const phoneIndex = _.findIndex(telecom, { system: "phone" });
  if (phoneIndex > -1) {
    const phone = telecom[phoneIndex];
    return phone.value;
  }
  return "";
}

export function fetchEmail(telecom = []) {
  // console.log(telecom)
  const emailIndex = _.findIndex(telecom, { system: "email" });
  if (emailIndex > -1) {
    const email = telecom[emailIndex];
    return email.value;
  }
  return "";
}

export const FormDivider = () => {
  return (
    <Grid item xs={12}>
      <Divider sx={{ my: 1, backgroundColor: 'grey' }} />
    </Grid>
  )
}



