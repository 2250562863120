import axios from "axios";
import {
  ResponseErrorInterceptor,
  ResponseInterceptor,
  ErrorInterceptor
} from "./request_interceptor";
import UserService from "./UserService";

const {
  REACT_APP_HEADER_DOCSTETH: Docsteth,
  REACT_APP_HEADER_CLINDESK: Clinidesk,
  REACT_APP_HEADER_MEDX: Medxperts,
  REACT_APP_REALM: REALM,
} = process.env;

/*clinidesk APIs

 /auth/v1/ - for Patient apis add clinidesk headers */

const API = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
});

/* /auth/v1/ for medxperts patient search */
export const M_API = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
});

// terminology valueset base url
export const TERMINOLOGY_VALUESET = axios.create({
  baseURL: process.env.REACT_APP_TERMINOLOGY_VALUESET,
});

/*  /auth/v1/ - docsteth headers for practitioner and organisation apis */
export const D_API = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
  headers: {
    gatewayToken: "clinidesk",
  },
});

//   /auth/v2/-- headers for practitioner and organisation apis
// export const D_API = (Id) => {
//     let res = axios.create({
//         baseURL: `${process.env.REACT_APP_CLINI}/${Id}`, headers: {
//             gatewayToken: 'clinidesk'
//         }
//     })
//     return res;
// }

export const D_API_ADD_ORG = axios.create({
  baseURL: process.env.REACT_APP_CLINI_ADD_ORG,
  headers: {
    gatewayToken: "clinidesk",
  },
});
/*  /auth/v1/ - docsteth headers for practitioner and organisation apis No Authorization*/
export const D_WITHOUT_TOKEN_API = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
});

/*  fhir-server/api/v4 - docsteth headers for practitioner and organisation apis */
export const D_API_FHIR = axios.create({
  baseURL: process.env.REACT_APP_FHIR_SERVER,
  // headers: {
  //     gatewayToken: 'clinidesk'
  // }
});
/*  fhir-server/api/v4 - docsteth headers for practitioner and organisation apis */
export const D_API_FHIR_CLINI = axios.create({
  baseURL: process.env.REACT_APP_FHIR_SERVER,
  headers: {
    gatewayToken: "clinidesk",
  },
});

/*  fhir-server/api/v4 - docsteth headers for practitioner and organisation apis No Authorization */
export const D_API_FHIR_WITHOUT_TOKEN = axios.create({
  baseURL: process.env.REACT_APP_FHIR_SERVER,
});

/* /api/v1/clindesk - docsteth headers for practitioner and organisation apis
for ABHA NDHM apis add gatewaytoken clinidesk */

export const C_API = axios.create({
  baseURL: process.env.REACT_APP_CLINIDESK,
  headers: {
    gatewayToken: "clinidesk",
  },
});

/* for python apis  */

/* /elib */

export const PY_API = axios.create({
  baseURL: process.env.REACT_APP_API_ELIB_URL,
});

/* /devcommunication */

export const PY_DOC_API = axios.create({
  baseURL: process.env.REACT_APP_COMMUNICATION,
});

/*search value for Location*/

export const PY_SEARCH_LOC = axios.create({
  baseURL: process.env.REACT_APP_WEBMASTER,
});

//for docsteth apis

export const DOC_API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}v1`,
  // headers: {
  //   Authorization: `bearer ${UserService.getToken()}`
  // }
});

export const DOC_API_WITHOUT_TOKEN = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}v1`,
  // headers: {
  //   Authorization: `bearer ${UserService.getToken()}`
  // }
});

export const DOCS_API_V1 = axios.create({
  baseURL: process.env.REACT_APP_CLINI_V1,
});

export const DOCS_WITHOUT_TOKEN = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
});

export const DOCS_WITHOUT_TOKEN_WITH_TENANTS = axios.create({
  baseURL: process.env.REACT_APP_CLINI,
});

// const RequestInterceptor = (realm) => (reqConfig) => {
//   try {
//     //   reqConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
//     const kctoken = sessionStorage.getItem("kctoken");
//     console.log(typeof kctoken,"z11")

//     reqConfig.headers["Authorization"] = `Bearer ${kctoken}`;
//     reqConfig.headers["X-FHIR-TENANT-ID"] = realm.toLowerCase();
//     reqConfig.headers["X-FHIR-DSID"] = `fhir${realm}`;
//     // reqConfig.headers['realm'] = REALM
//     reqConfig.headers["realm"] = realm.toLowerCase();
//     return reqConfig;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// RequestInterceptor
const RequestInterceptor = (realm) => (reqConfig) => {
  try {

    const kctoken = sessionStorage.getItem("kctoken");

    if (typeof kctoken !== 'string' || kctoken.trim() === '') {
      console.error('Invalid token:', kctoken);
      return Promise.reject('Invalid token');
    }

    reqConfig.headers["Authorization"] = `Bearer ${kctoken}`;
    reqConfig.headers["X-FHIR-TENANT-ID"] = realm.toLowerCase();
    reqConfig.headers["X-FHIR-DSID"] = `fhir${realm}`;
    reqConfig.headers["realm"] = realm.toLowerCase();

    return reqConfig;
  } catch (error) {
    console.error('Error in RequestInterceptor:', error);
    return Promise.reject(error);
  }
};


const RequestWitoutTokenInterceptor = (realm) => (reqConfig) => {
  try {
    //   reqConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    reqConfig.headers["X-FHIR-TENANT-ID"] = realm.toLowerCase();
    reqConfig.headers["X-FHIR-DSID"] = `fhir${realm}`;
    reqConfig.headers["realm"] = realm.toLowerCase();
    // reqConfig.headers['realm'] = REALM
    return reqConfig;
  } catch (error) {
    return Promise.reject(error);
  }
};

const ReqInterceptorWithTokenAndRealm = (realm) => (reqConfig) => {
  try {
    const kctoken = UserService.getToken();
    reqConfig.headers["Authorization"] = `Bearer ${kctoken}`;
    reqConfig.headers["realm"] = realm.toLowerCase();
    return reqConfig;
  } catch (error) {
    return Promise.reject(error);
  }
};

const DOCInterceptor = (reqConfig) => {
  try {
    //   reqConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    const kctoken = UserService.getToken();
    reqConfig.headers["Authorization"] = `Bearer ${kctoken}`;
    return reqConfig;
  } catch (error) {
    return Promise.reject(error);
  }
};

/* for patient - Clinidesk headers for clindesk apis */

API.interceptors.request.use(RequestInterceptor(Clinidesk));
API.interceptors.response.use(ResponseInterceptor, ResponseErrorInterceptor);






/* for medxperts patient medxperts headers */
M_API.interceptors.request.use(RequestInterceptor(Medxperts));
M_API.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);
/* /auth/v1 docsteth headers for clindesk apis */
D_API.interceptors.request.use(RequestInterceptor(Docsteth));
// Adding new scenario for handling response config file
D_API.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);

/* /auth/v1 docsteth headers for clindesk apis No Authorization */
// D_API.interceptors.request.use(RequestWitoutTokenInterceptor(Docsteth));

/* fhir-server/api/v4 docsteth headers for clindesk apis */
D_API_FHIR.interceptors.request.use(RequestInterceptor(Docsteth));
D_API_FHIR.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);
/* fhir-server/api/v4 docsteth headers for clindesk apis */
D_API_FHIR_CLINI.interceptors.request.use(RequestInterceptor(Clinidesk));
D_API_FHIR_CLINI.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);

/* /api/v1/clindesk docsteth headers for clindesk apis */
C_API.interceptors.request.use(RequestInterceptor(Docsteth));
C_API.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);

//Authorization headers
DOC_API.interceptors.request.use(DOCInterceptor);
DOC_API.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);
PY_DOC_API.interceptors.request.use(DOCInterceptor);
PY_DOC_API.interceptors.response.use(ResponseInterceptor,ErrorInterceptor);
DOCS_WITHOUT_TOKEN.interceptors.request.use(
  RequestWitoutTokenInterceptor(Docsteth)
);
DOCS_API_V1.interceptors.request.use(ReqInterceptorWithTokenAndRealm(Docsteth));
DOCS_WITHOUT_TOKEN_WITH_TENANTS.interceptors.request.use(RequestWitoutTokenInterceptor(Docsteth));

export default API;
