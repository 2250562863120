import * as React from 'react';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DomainIcon from '@mui/icons-material/Domain'
import IconButton from '@mui/material/IconButton'
import axios from 'axios';
import URL from '../../../apiurl';
import { Grid, } from '@mui/material';
import { getMetaData } from '../../../../redux/Actions/actions';
import Drop from '../../../Fields/CssTextField'
import Organisation from '../../../Atom/modifiedicons/Organisations.svg'
import UserService from '../../../../services/UserService';
import '../updateprofile.css';
import InputField from '../../../Fields/TextFields';

const filter = createFilterOptions();

const GridItem = (props) => {
    // console.log(props)
    return (
        <Grid item sm={12} xs={12} style={{ padding: '0px 8px' }}>
            <InputField
                label={props.label}
                // placeholder="Enter Address" 
                value={props.value} name={props.name} handleChange={props.handleChange}
                // validators={['required']}
                // errorMessages={['Please Enter Address']}
                disabled={props.disabled}
            />
        </Grid>
    )
}


export default function FreeSoloCreateOptionDialog(props) {
    console.log(props, "uuuuuu")
    const [value, setValue] = React.useState(props.value ? props.value : '');
    const [open, toggleOpen] = React.useState(false);
    const [colleges, setColleges] = React.useState([])
    let userId = sessionStorage.getItem("userId")

    const [locations, setLocations] = React.useState([])
    const [text, setText] = React.useState('Search Locations')

    React.useEffect(() => {
        // getOrg();
        // searchCollege()
    }, [])

    const searchCollege = (value) => {
        // console.log(value, "valueeeeeeeee")
        if (value.length > 0) {
            axios.get(`${URL.searchCollege}/${value}`, {
                headers: {
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then(res =>
                // console.log(res)
                setColleges(res.data.data)
            ).catch(error => { })
        }
    }
    // console.log(colleges)
    // const getOrg = () => {
    //     axios.get(`${URL.getPagesByName}`, {
    //         headers: {
    //             'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
    //         }
    //     })
    //         .then(response => response.data)
    //         .then(res => {
    //             let organisation = res.data
    //             // organisation.unshift({ id: Math.random(), name: 'Add Organisation', description: '', addressId: '' })
    //             setOrg(organisation)
    //         })
    //         .catch(error => { })
    // }

    const handleClose = () => {
        setDialogValue({
            organisationName: '',
            addressId: '',
            description: '',
            location: ''
        });
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        organisationName: '',
        addressId: '',
        description: '',
        location: ""
    });

    console.log(dialogValue, "dialogValue")

    const handleSubmit = async (event) => {
        event.preventDefault();
        setValue({
            name: dialogValue.organisationName,
            location: dialogValue.location,
            organisationType: dialogValue.description
        });
        if (dialogValue.organisationName !== '') {
            axios.post(`${URL.addOrganisation}`, { ...dialogValue, userId: userId, metadata: await getMetaData() }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
                }
            })
                .then(res => {
                    console.log(res.data.data, "vamshiiiiiiiiiiiii")
                    props.handleOrg(res.data.data.organisationName, '', true)
                    // getOrg()
                    handleClose();
                }).catch(error => { })
        }
    };

    const getLocation = (value) => {
        if (value !== '') {
            setText('No Options')
            axios.get(`${URL.searchLocation}/${value}`).then(res => setLocations(res.data.data.predictions.map(data => data.description)))
        }
        else {
            setText('Search Location')
            setLocations([])
        }
    }

    return (
        <React.Fragment>
            {!open ?
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        { console.log(newValue) }
                        { console.log(newValue, newValue.inputValue, "iiiii") }
                        if (newValue !== null) {
                            if (newValue.name === "Add Organisation") {
                                toggleOpen(true)
                                setDialogValue({
                                    organisationName: '',
                                    addressId: '',
                                    description: ''
                                })
                            }
                            else if (newValue && newValue.inputValue) {
                                toggleOpen(true)
                                setDialogValue({
                                    organisationName: newValue.inputValue,
                                    addressId: '',
                                    description: ''
                                })
                            }
                            else {

                                props.handleOrg(newValue, false)
                                setValue(newValue)

                            }
                        }
                        else {
                            setValue({ organisationName: '' })
                        }
                    }}
                    onInputChange={(e) => {
                        // console.log(e.target.value, "eeeeeeeee")
                        if (e !== null) {
                            // if (e.target.value !== null) {
                            setValue(e.target.value)

                            if (e && e.target.value !== 0) {
                                searchCollege(e.target.value)
                                // { console.log(value) }
                                // props.handleOrg(e.target.value, null, true)
                            }
                        }
                    }}
                    // onInputChange={(e) => {
                    //     if (e && e.target.value !== 0) searchCollege(e.target.value)
                    // }}
                    filterOptions={(options, params) => {
                        // console.log(params)
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                name: `Add Organisation '${params.inputValue}'`,
                            });
                        }
                        // {console.log(filtered)}
                        return filtered;
                    }}

                    id="free-solo-dialog-demo"
                    options={colleges}
                    freeSolo
                    // getOptionLabel={(option) =>
                    //     // { console.log(option.inputValue, "ghjkl", option.name) }
                    //     option.collegeName || option || option.inputValue || ''
                    // }

                    getOptionLabel={(option) => {
                        // { console.log(option, "3333333331111") }
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.collegeName) {
                            // { console.log(option.collegeName, "collegeName") }
                            return option.collegeName;
                        }
                        // {console.log(option)}
                        return option.name || option || '';
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => <li {...props} key={option.index || option.id || option} value={option.collegeName || option.name || option}>
                        {option.logo ? <img src={Organisation} width="20px" height="20px" alt="organisation" style={{ paddingRight: "10px", paddingLeft: "10px" }} /> : <IconButton><DomainIcon /></IconButton>}{option.collegeName || option.name || option}</li>}
                    sx={{ mt: 2 }}
                    renderInput={(params) => {
                        return (
                            <Drop {...params} label={props.label} value={params.inputProps.value} validators={['required']}
                                errorMessages={[`Please Select ${props.label}`]} />)
                    }}
                />
                :
                <Grid container columnSpacing={1} className='border_new_org' sx={{ border: "1px solid red" }}>
                    <p className='add_new_org'>Add new Organisation</p>
                    <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                        <Drop
                            fullWidth
                            margin="dense"
                            id="name"
                            value={dialogValue.organisationName}
                            onChange={(event) => {
                                setDialogValue({
                                    ...dialogValue,
                                    organisationName: event.target.value,
                                })
                            }
                            }
                            label="Organisation Name"
                            type="text"
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Enter Organisation Name']}
                        />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ paddingLeft: "10px" }}>

                        <Autocomplete
                            options={locations}
                            getOptionLabel={option => option ? option : ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            value={dialogValue.location}
                            onChange={(event, value) => {
                                setDialogValue({ ...dialogValue, location: value })
                            }
                            }
                            // onChange={handlecity}
                            noOptionsText={text}
                            onInputChange={(e, value) => getLocation(value)}
                            filterOptions={(x) => x}
                            renderInput={params => (
                                <Drop
                                    {...params}
                                    margin="dense"
                                    label="city"
                                    variant="outlined"
                                    value={dialogValue.location}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    {/* {(state.organisationName) ? " " : */}
                    <GridItem label="Issuer (University)" value={dialogValue.organisationName ? dialogValue.organisationName : ""} disabled={true} name="issuer" />
                    {/* } */}
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        {dialogValue.organisationName !== '' ? <Button onClick={handleSubmit}>Add</Button> : ''}
                    </Grid>
                </Grid>}
        </React.Fragment>
    );
}
