// import { getPractitionerData } from "../../features/common/functions/functions"
import { getPractitionerData } from "../../Components/common/functions/functions"
import { C_API, DOC_API, D_API, D_API_FHIR } from "../../services/APIService"
// import { URI } from "../../services/uri"
import { URI } from "../../Components/utility/uri"
import UserService from "../../services/UserService"
import axios from "axios"
import { jwtDecode } from "jwt-decode"

export const headers = () => {
    return {
        headers: {
            Authorization: `bearer ${UserService.getToken()}`
        }
    }
}

export const userId = UserService.getUserId()


export const getActiveStep = () => {
    return dispatch => {
        C_API.get(URI.getStepsByUserId + UserService.getUserId()).then(res => {
            dispatch({
                type: 'ACTIVE_STEP',
                payload: res.data
            })
        })
    }
}

export const regData = (data, type) => {
    return (dispatch, state) => {
        let obj = state().registration.regData
        dispatch({
            type: 'REG_DATA',
            payload: { ...obj, [type]: data }
        })
    }
}

export const facilityData = (data, type) => {
    return (dispatch, state) => {
        let obj = state().registration.facilityData
        dispatch({
            type: 'FACILITY_DATA',
            payload: { ...obj, [type]: data }
        })
    }
}

export const connectData = (data, type) => {
    return (dispatch, state) => {
        let obj = state().registration.connectData
        dispatch({
            type: 'CONNECT_DATA',
            payload: { ...obj, [type]: data }
        })
    }
}

export const getUserDetails = () => {
    return async dispatch => {
        let p_data = await getPractitionerData()

        if (p_data.entry) {
            dispatch({
                type: "USER_DATA",
                payload: { practioner: p_data.entry[0].resource }
            })
        }
        else {
            DOC_API.get(`/findUsersById/${UserService.getUserId()}`)
                .then(res => {
                    dispatch({
                        type: "USER_DATA",
                        payload: res.data
                    })
                })
        }
    }
}

export const getOrganisation = (data) => {
    return dispatch => {
        dispatch({
            type: 'ORG_DATA',
            payload: data
        })
    }
}

export const getLocation = (id) => {
    // console.log(data)
    console.log(id, "id here 1")
    if (id) {
        return dispatch => {
            D_API.get(`${id}/${URI.getLocation}${id}`).then(res => {
                console.log(res.data)
                let data = res.data?.entry?.[0]?.resource
                dispatch({
                    type: 'location',
                    payload: { ...data }
                })
            }).catch(() => {
                dispatch({
                    type: 'location',
                    payload: {}
                })
            })
        }
    }

}

const headers1 = () => {
    return (
        {
            headers: {
                'X-FHIR-TENANT-ID': 'docsteth',
                'X-FHIR-DSID': 'fhirDocsteth',
                'Realm': 'docsteth'
            }
        }
    )
}

export const getLocation_withouttoken = (id) => {
    // console.log(data)
    console.log(id, "id here 1")
    return dispatch => {
        axios.get(process.env.REACT_APP_FHIR_SERVER + `Location?organization=` + id, headers1()).then(res => {
            console.log(res.data)
            let data = res.data?.entry?.[0]?.resource
            dispatch({
                type: 'location_withouttoken',
                payload: { ...data }
            })
        }).catch(() => {
            dispatch({
                type: 'location_withouttoken',
                payload: {}
            })
        })
    }
}
export const fetchSubOrganization = (data) => {
    return dispatch => {
        dispatch({
            type: 'FETCH_SUB_ORGANIZATIONS',
            payload: data
        })
    }
}



export const fetchPublicRouteSubOrganization = (data) => {
    return dispatch => {
        dispatch({
            type: 'FETCH_SUB_ORGANIZATIONS_IN_PUBLIC_ORGANIZATION',
            payload: data
        })
    }
}

export const getUserOrg = (data) => {
    console.log(data, 'hi')
    return dispatch => {
        dispatch({
            type: 'USER_ORG',
            payload: data
        })
    }
}

export const handleUserOrg = (data) => {
    console.log(data, "data")
    return dispatch => {
        dispatch({
            type: 'USER_ORG_DATA',
            payload: data
        })
    }
}

export const handleHealthDeptOrg = (data) => {
    return dispatch => {
        dispatch({
            type: 'HEALTH_DEPARTMENT',
            payload: data,
        })
    }
}


export const handleHealthDeptLoc = (data) => {
    return dispatch => {
        dispatch({
            type: 'HEALTH_DEPARTMENT_LOCATION',
            payload: data
        })
    }
}


export const handleHealthServiceOrgInfo = (data) => {
    return dispatch => {
        dispatch({
            type: 'HEALTH_SERVICE_INFO',
            payload: data
        })
    }
}

export const handleHealthServiceLoc = (data) => {
    return dispatch => {
        dispatch({
            type: 'HEALTH_SERVICE_LOCATION',
            payload: data
        })
    }

}

export const handleSubHealthServiceLoc = (data) => {
    return dispatch => {
        dispatch({
            type: 'SUB_HEALTH_SERVICE_LOCATION',
            payload: data
        })
    }

}
export const handleSubHealthServiceOrgInfo = (data) => {
    return dispatch => {
        dispatch({
            type: 'SUB_HEALTH_SERVICE_INFO',
            payload: data
        })
    }

}
export const gethealthDeptListByPgId = (data) => {
    return dispatch => {
        dispatch({
            type: 'HEALTH_DEPT_BY_PAGEID_LIST',
            payload: data
        })
    }
}

export const getFacilities = (Id) => {
    return (dispatch, state) => {
        D_API.get(`${Id}/${URI.getOrganization}${UserService.getUserId()}`).then(res => {

            if (res.data.total > 10) {
                // D_API.get(URI.getOrganization + UserService.getUserId() + "?count=" + res.data.total).then(res => {
                D_API.get(`${Id}/${URI.getOrganization}${UserService.getUserId()}?count=${res.data.total}`).then(res => {
                    dispatch({
                        type: 'FACILITY_LIST',
                        payload: res.data.entry || [],
                        orgResource: res.data
                    })
                })
            }
            else {
                dispatch({
                    type: 'FACILITY_LIST',
                    payload: res.data.entry || [],
                    orgResource: res.data
                })
            }
        })
    }
}

export const getPractitionerFromFhir = () => {
    return (dispatch) => {
        let token = sessionStorage?.getItem("kctoken");

        if (typeof token !== 'string' || token.trim() === '') {
            console.error('Invalid token:', token);
            return;
        }


        try {
            let tokenInfo = jwtDecode(token);
            if (tokenInfo?.ibmId) {
                D_API.get(`Practitioner/${tokenInfo?.ibmId}`).then((res) => {
                    dispatch({
                        type: 'FETCH_USER_DATA',
                        payload: res.data
                    });
                }).catch((err) => {
                    console.error("Error fetching practitioner:", err);
                });
            } else {
                console.error('Token does not contain ibmId:', tokenInfo);
            }

        } catch (error) {
            console.error('Error decoding token:', error);
        }
    }
}


// export const getPractitionerFromFhir = () => {
//     return (dispatch) => {
//         let token= sessionStorage?.getItem("kctoken")
//         console.log( sessionStorage?.getItem("kctoken"),"tokenInfo")
//         // let tokenInfo = jwtDecode(sessionStorage?.getItem("kctoken"));
//         let tokenInfo = jwtDecode(token);
//         // if (typeof tokenInfo !== 'string' || tokenInfo.trim() === '') {
//         //     console.error('Invalid token:', tokenInfo);
//         //     return Promise.reject('Invalid token');
//         // }
//         if (tokenInfo?.ibmId) {
//             D_API.get(`Practitioner/${tokenInfo?.ibmId}`).then((res) => {
//                 dispatch({
//                     type: 'FETCH_USER_DATA',
//                     payload: res.data
//                 })
//             })
//         }

//     }
// }

export const getIsAdmin = (userOrg) => {
    return (dispatch) => {
        if (userOrg?.id) {
            D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: res.data
                })
            }).catch((err) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: null
                })
                console.log(err)
            })
        } else {
            dispatch({
                type: "IS_ADMIN",
                payload: null
            })
        }

    }

}